import { appConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  categories: [],
  sections: [],
  users: [],  
  dashboardData: null,
  hospital: null,
  filterShortcut: false,
  patientShortcut: false,
  printShortcut: false,
  rescheduleShortcut: false,
  redirect: ""
};

const appReducer = (state = initState, action) => {    
  switch (action.type) {    
    case appConstants.GET_INITIAL_DATA_REQUEST:            
    case appConstants.UPDATE_PROFILE_REQUEST:
    case appConstants.UPDATE_PASSWORD_REQUEST:
    case appConstants.UPDATE_HOSPITAL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case appConstants.SET_REDIRECT:
      return {
        ...state,
        redirect: action.payload
      }

    case appConstants.GET_INITIAL_DATA_SUCCESS:
      return {
        ...state,      
        categories: action.payload?.categories || [],    
        sections: action.payload?.sections || [],
        users: action.payload?.users || [],
        dashboardData: action.payload?.dashboardData || null,
        hospital: action.payload?.hospital || null,
        loading: false,
      };  
      
    case appConstants.UPDATE_PROFILE_SUCCESS:
    case appConstants.UPDATE_PASSWORD_SUCCESS:
    case appConstants.UPDATE_HOSPITAL_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        loading: false,
      };

    case appConstants.GET_INITIAL_DATA_FAILURE:            
    case appConstants.UPDATE_PROFILE_FAILURE:
    case appConstants.UPDATE_PASSWORD_FAILURE:
    case appConstants.UPDATE_HOSPITAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };  
    
    case appConstants.UPDATE_FILTER_SHORTCUT:
      return {
        ...state,
        filterShortcut: action.payload,
      };

    case appConstants.UPDATE_PATIENT_SHORTCUT:
      return {
        ...state,
        patientShortcut: action.payload,
      };

    case appConstants.UPDATE_PRINT_SHORTCUT:
      return {
        ...state,
        printShortcut: action.payload,
      };

    case appConstants.UPDATE_RESCHEDULE_SHORTCUT:
      return {
        ...state,
        rescheduleShortcut: action.payload,
      };

    case appConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case appConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default appReducer;