import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import VaccinesOutlinedIcon from "@mui/icons-material/VaccinesOutlined";

const icons = {
  AccountTreeOutlinedIcon,
  GroupOutlinedIcon,
  VaccinesOutlinedIcon  
};

const pages = {
  id: "pages",
  title: "Administration",
  type: "group",
  children: [
    {
      id: "section",
      title: "Sections",
      type: "item",
      url: "/section",
      icon: icons.AccountTreeOutlinedIcon,
      breadcrumbs: true,
      roles: [1]
    },
    {
      id: "user",
      title: "Users",
      type: "item",
      url: "/user",
      icon: icons.GroupOutlinedIcon,
      breadcrumbs: true,
      roles: [1]
    },
    {
      id: "hospital",
      title: "Hospital",
      type: "item",
      url: "/hospital",
      icon: icons.VaccinesOutlinedIcon,
      breadcrumbs: true,
      roles: [1]
    }    
  ]
};

export default pages;