import { useMemo } from "react";
import { useRegisterShortcut } from "react-keybinds";
import { useDispatch } from "react-redux";

import { appConstants } from "redux/constants";

const KeyboardShortcuts = () => {  

  const dispatch = useDispatch();    

  const filterShortcut = useMemo(
    () => ({
      keys: {
        Windows: ["Control", "Alt", "f"],
      },
      id: "1",
      label: "Filter Patients",
      description: "Press Ctrl + Alt + F to open the filter dialog.(Works on Patient List Page and Appointment List Page)",
      display: "Ctrl + Alt + F",
      callback: () => {
        dispatch({
          type: appConstants.UPDATE_FILTER_SHORTCUT,
          payload: true,
        })        
      },
    }),
    [dispatch]
  );

  const patientShortcut = useMemo(
    () => ({
      keys: {
        Windows: ["Control", "."],
      },
      id: "2",
      label: "Add New Patient",
      description: "Press Ctrl + . to add a new patient.(Works on Patient List Page)",
      display: "Ctrl + .",
      callback: () => {          
        dispatch({
          type: appConstants.UPDATE_PATIENT_SHORTCUT,
          payload: true,
        })               
      },
    }),    
    [dispatch]
  );

  const printShortcut = useMemo(
    () => ({
      keys: {
        Windows: ["Control", ","],
      },
      id: "3",
      label: "Print Appointment Slip",
      description: "Press Ctrl + , to print the appointment slip.(Works on Appointment Details Page)",
      display: "Ctrl + ,",
      callback: () => {       
        dispatch({
          type: appConstants.UPDATE_PRINT_SHORTCUT,
          payload: true,
        })         
      },
    }),
    [dispatch]
  );

  const rescheduleShortcut = useMemo(
    () => ({
      keys: {
        Windows: ["Control", "Alt", "r"],
      },
      id: "4",
      label: "Reschedule Appointment",
      description: "Press Ctrl + Alt + R to reschedule the appointment.(Works on Appointment Details Page)",
      display: "Ctrl + Alt + R",
      callback: () => {     
        dispatch({
          type: appConstants.UPDATE_RESCHEDULE_SHORTCUT,
          payload: true,
        })         
      }
    }),
    [dispatch]
  );
  
  useRegisterShortcut(filterShortcut);
  useRegisterShortcut(patientShortcut);
  useRegisterShortcut(printShortcut);
  useRegisterShortcut(rescheduleShortcut);
}

export default KeyboardShortcuts;