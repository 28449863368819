import { dataConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null, 
  appointmentData: null,
  appointment: null,
};

const dataReducer = (state = initState, action) => {  
  switch (action.type) {    
    case dataConstants.MANAGE_HOSPITAL_SECTION_REQUEST:
    case dataConstants.MANAGE_USER_REQUEST:
    case dataConstants.GET_APPOINTMENTS_REQUEST:
    case dataConstants.GET_APPOINTMENT_REQUEST:
    case dataConstants.RESCHEDULE_APPOINTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case dataConstants.MANAGE_HOSPITAL_SECTION_SUCCESS:
    case dataConstants.MANAGE_USER_SUCCESS:
    case dataConstants.RESCHEDULE_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload.message
      };

    case dataConstants.GET_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        appointmentData: action.payload,
        loading: false,
      };

    case dataConstants.GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointment: action.payload,
        loading: false,
      };

    case dataConstants.MANAGE_HOSPITAL_SECTION_FAILURE:
    case dataConstants.MANAGE_USER_FAILURE:
    case dataConstants.GET_APPOINTMENTS_FAILURE:
    case dataConstants.GET_APPOINTMENT_FAILURE:
    case dataConstants.RESCHEDULE_APPOINTMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
      
    case dataConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case dataConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default dataReducer;