import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined';

const icons = {  
  AssessmentOutlinedIcon, 
  VaccinesOutlinedIcon 
};

const report = {
  id: "report",
  title: "Reports",
  type: "group",
  children: [    
    {
      id: "appointment-report",
      title: "Appointment Register",
      type: "item",
      url: "/report/appointment",
      icon: icons.AssessmentOutlinedIcon,
      breadcrumbs: true,
      roles: [1,2,3,4]
    },
    {
      id: "patient-report",
      title: "Patient Register",
      type: "item",
      url: "/report/patient",
      icon: icons.VaccinesOutlinedIcon,
      breadcrumbs: true,
      roles: [1,2,3,4]
    }    
  ]
};

export default report;