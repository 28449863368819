import { lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import { appConstants } from "redux/constants";

const Dashboard = Loadable(lazy(() => import("pages/dashboard")));
const Patient = Loadable(lazy(() => import("pages/patient")));
const Section = Loadable(lazy(() => import("pages/section")));
const User = Loadable(lazy(() => import("pages/user")));
const Appointment = Loadable(lazy(() => import("pages/appointment")));
const AppointmentDetail = Loadable(lazy(() => import("pages/appointment/detail")));
const Token = Loadable(lazy(() => import("pages/token")));
const Profile = Loadable(lazy(() => import("pages/profile")));
const Hospital = Loadable(lazy(() => import("pages/hospital")));
const AppointmentReport = Loadable(lazy(() => import("pages/report/appointment")));
const PatientReport = Loadable(lazy(() => import("pages/report/patient")));

const PrivateRoute = ({ children }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { redirect } = useSelector(state => state.app);
  const { userLoading, isAuthenticated } = useSelector(state => state.user);

  useEffect(() => {
    if (redirect) {
      navigate(redirect);
      dispatch({
        type: appConstants.SET_REDIRECT,
        payload: ""
      });
    }
  }, [redirect, navigate, dispatch]);

  return !userLoading && 
    (!isAuthenticated ? 
      <Navigate to={`/auth/login?redirect=${encodeURIComponent(window.location.pathname)}`} />
    : children); 
};

const AuthorizedRoute = ({ roles, children }) => {
  const { user } = useSelector(state => state.user);  
  return user &&
    (roles?.includes(user?.role) ? children : <Navigate to="/404" />);
}

const MainRoutes = {
  path: "/",
  element: <PrivateRoute><MainLayout/></PrivateRoute>,
  children: [
    {
      path: "",
      element: <Dashboard/>
    },
    {
      path: "patient",
      element: <AuthorizedRoute roles={[1,4]}><Patient/></AuthorizedRoute>
    },
    {
      path: "section",
      element: <AuthorizedRoute roles={[1]}><Section/></AuthorizedRoute>
    },
    {
      path: "user",
      element: <AuthorizedRoute roles={[1]}><User/></AuthorizedRoute>
    },
    {
      path: "appointment",
      children: [
        {
          path: "",
          element: <AuthorizedRoute roles={[1,2,3,4]}><Appointment/></AuthorizedRoute>
        },
        {
          path: ":id",
          element: <AuthorizedRoute roles={[1,4]}><AppointmentDetail/></AuthorizedRoute>
        }
      ]
    },
    {
      path: "token",
      element: <AuthorizedRoute roles={[1,2,3,5]}><Token/></AuthorizedRoute>
    },
    {
      path: "report",
      children: [
        {
          path: "appointment",
          element: <AuthorizedRoute roles={[1,2,3,4]}><AppointmentReport/></AuthorizedRoute>
        },
        {
          path: "patient",
          element: <AuthorizedRoute roles={[1,2,3,4]}><PatientReport/></AuthorizedRoute>
        }
      ]    
    },
    {
      path: "profile",
      children: [
        {
          path: "",
          element: <Profile/>
        },
        {
          path: "edit",
          element: <Profile/>
        }
      ]
    },
    {
      path: "hospital",
      element: <AuthorizedRoute roles={[1]}><Hospital/></AuthorizedRoute>
    }     
  ]
};

export default MainRoutes;