import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Loading } from "./@extended/Loading";
import {     
  appConstants,  
  dataConstants,
  patientConstants,
  reportConstants,   
  userConstants 
} from "redux/constants";

const MessageAndErrors = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();    
  const { message: appMsg, error: appErr, loading: appLoading } = useSelector(state => state.app);      
  const { message: dataMsg, error: dataErr, loading: dataLoading } = useSelector(state => state.data);
  const { message: patientMsg, error: patientErr, loading: patientLoading } = useSelector(state => state.patient);
  const { message: reportMsg, error: reportErr, loading: reportLoading } = useSelector(state => state.report);
  const { message: userMsg, error: userErr, loading: userLoading, redirectLogin } = useSelector(state => state.user);

  useEffect(() => {    
    if(appMsg){
      toast.success(appMsg)
      dispatch({ type: appConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(appErr){
      toast.error(appErr)
      dispatch({ type: appConstants.RESET_ERROR_AND_MESSAGE })
    }    
    if(dataMsg){
      toast.success(dataMsg)
      dispatch({ type: dataConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(dataErr){
      toast.error(dataErr)
      dispatch({ type: dataConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(patientMsg){
      toast.success(patientMsg)
      dispatch({ type: patientConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(patientErr){
      toast.error(patientErr)
      dispatch({ type: patientConstants.RESET_ERROR_AND_MESSAGE })
    }  
    if(reportMsg){
      toast.success(reportMsg)
      dispatch({ type: reportConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(reportErr){
      toast.error(reportErr)
      dispatch({ type: reportConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(userMsg){
      toast.success(userMsg)
      dispatch({ type: userConstants.RESET_ERROR_AND_MESSAGE })
    }
    if(userErr){
      toast.error(userErr)
      dispatch({ type: userConstants.RESET_ERROR_AND_MESSAGE })
    }  
    if(redirectLogin){      
      navigate(`/auth/login`)
      dispatch({ type: userConstants.RESET_ERROR_AND_MESSAGE })
    }  
  }, [
    dispatch,    
    appMsg, 
    appErr,
    dataMsg,
    dataErr,
    patientMsg,
    patientErr,
    reportMsg,
    reportErr, 
    userMsg, 
    userErr,
    redirectLogin,
    navigate
  ]);

  return (
    <>
      <ToastContainer         
        style={{ fontSize: "12px" }}  
        position="top-right"      
      />
      <Loading
        loading={           
          appLoading ||          
          dataLoading ||
          patientLoading ||
          reportLoading ||
          userLoading
        }
      />      
    </>
  )
}

export default MessageAndErrors;