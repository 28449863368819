import { patientConstants } from "redux/constants";
import Axios from "utils/axios";

export const managePatient = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: patientConstants.MANAGE_PATIENT_REQUEST });      
      let _data;
      if(id){
        _data = await Axios.put(`/patient/${id}`, form);
      }else{
        _data = await Axios.post(`/patient`, form);
      }
      const { data } = _data;      
      dispatch({ 
        type: patientConstants.MANAGE_PATIENT_SUCCESS,
        payload: {
          data: data?.data,
          message: data?.message
        }
      });
    }catch(error){
      dispatch({ 
        type: patientConstants.MANAGE_PATIENT_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getPatients = (page, limit, filter) => {
  return async dispatch => {
    try{
      dispatch({ type: patientConstants.GET_PATIENTS_REQUEST });      
      let URL = `/patient?page=${page}&limit=${limit}`;
      if(filter?.query) URL += `&query=${filter?.query}`;
      if(filter?.gender) URL += `&gender=${filter?.gender}`;
      if(filter?.categoryId) URL += `&categoryId=${filter?.categoryId}`;
      const { data } = await Axios.get(URL);
      dispatch({ 
        type: patientConstants.GET_PATIENTS_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: patientConstants.GET_PATIENTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}