import { DashboardOutlined } from "@ant-design/icons";
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import HealingOutlinedIcon from '@mui/icons-material/HealingOutlined';
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';

const icons = {    
  DashboardOutlined,  
  DateRangeOutlinedIcon,
  HealingOutlinedIcon,
  TokenOutlinedIcon  
};

const dashboard = {
  id: "dashboard",
  title: "Navigation",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/",
      icon: icons.DashboardOutlined,
      breadcrumbs: true,
      roles: [1,2,3,4]
    },
    {
      id: "patient",
      title: "Patients",
      type: "item",
      url: "/patient",
      icon: icons.HealingOutlinedIcon,
      breadcrumbs: true,      
      roles: [1,4]      
    },
    {
      id: "appointment",
      title: "Appointments",
      type: "item",
      url: "/appointment",
      icon: icons.DateRangeOutlinedIcon,
      breadcrumbs: true,      
      roles: [1,2,3,4]      
    },
    {
      id: "token",
      title: "Tokens",
      type: "item",
      url: "/token",
      icon: icons.TokenOutlinedIcon,
      breadcrumbs: true,
      roles: [1,2,3,5]
    },    
    {
      id: "profile",
      title: "Profile",
      type: "item",
      url: "/profile",
      icon: icons.DashboardOutlinedIcon,
      breadcrumbs: true,
      roles: [1,2,3,4,5],
      display: false
    },    
    {
      id: "profile-edit",
      title: "Edit Profile",
      type: "item",
      url: "/profile/edit",
      icon: icons.DashboardOutlinedIcon,
      breadcrumbs: true,
      roles: [1,2,3,4,5],
      display: false
    }        
  ]
};

export default dashboard;