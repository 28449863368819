import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { KeyBindProvider } from "react-keybinds";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "simplebar/src/simplebar.css";

import App from "./App";
import store from "redux/store";
import reportWebVitals from "./reportWebVitals";
import ThemeCustomization from "themes";
import PingWrapper from "components/PingWrapper";
import "themes/style.css";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <StrictMode>    
    <ReduxProvider store={store}>
      <KeyBindProvider>
        <BrowserRouter>
          <ThemeCustomization>
            <PingWrapper>
              <App />
            </PingWrapper>
          </ThemeCustomization>
        </BrowserRouter>
      </KeyBindProvider>
    </ReduxProvider>
  </StrictMode>
);

reportWebVitals();
