export const menuConstants = {
  ACTIVE_ITEM: "ACTIVE_ITEM",
  ACTIVE_COMPONENT: "ACTIVE_COMPONENT",
  OPEN_DRAWER: "OPEN_DRAWER",
  OPEN_COMPONENT_DRAWER: "OPEN_COMPONENT_DRAWER"
};

export const userConstants = {  
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  LOAD_USER_REQUEST: "LOAD_USER_REQUEST",
  LOAD_USER_SUCCESS: "LOAD_USER_SUCCESS",
  LOAD_USER_FAILURE: "LOAD_USER_FAILURE",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  COMPLETE_PROFILE_REQUEST: "COMPLETE_PROFILE_REQUEST",
  COMPLETE_PROFILE_SUCCESS: "COMPLETE_PROFILE_SUCCESS",
  COMPLETE_PROFILE_FAILURE: "COMPLETE_PROFILE_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const appConstants = {
  GET_INITIAL_DATA_REQUEST: "GET_INITIAL_DATA_REQUEST",
  GET_INITIAL_DATA_SUCCESS: "GET_INITIAL_DATA_SUCCESS",
  GET_INITIAL_DATA_FAILURE: "GET_INITIAL_DATA_FAILURE",      
  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",
  UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",  
  UPDATE_HOSPITAL_REQUEST: "UPDATE_HOSPITAL_REQUEST",
  UPDATE_HOSPITAL_SUCCESS: "UPDATE_HOSPITAL_SUCCESS",
  UPDATE_HOSPITAL_FAILURE: "UPDATE_HOSPITAL_FAILURE",
  UPDATE_FILTER_SHORTCUT: "UPDATE_FILTER_SHORTCUT",
  UPDATE_PATIENT_SHORTCUT: "UPDATE_PATIENT_SHORTCUT",
  UPDATE_PRINT_SHORTCUT: "UPDATE_PRINT_SHORTCUT",
  UPDATE_RESCHEDULE_SHORTCUT: "UPDATE_RESCHEDULE_SHORTCUT",
  SET_REDIRECT: "SET_REDIRECT",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const dataConstants = {  
  MANAGE_HOSPITAL_SECTION_REQUEST: "MANAGE_HOSPITAL_SECTION_REQUEST",
  MANAGE_HOSPITAL_SECTION_SUCCESS: "MANAGE_HOSPITAL_SECTION_SUCCESS",
  MANAGE_HOSPITAL_SECTION_FAILURE: "MANAGE_HOSPITAL_SECTION_FAILURE",
  MANAGE_USER_REQUEST: "MANAGE_USER_REQUEST",
  MANAGE_USER_SUCCESS: "MANAGE_USER_SUCCESS",
  MANAGE_USER_FAILURE: "MANAGE_USER_FAILURE",
  GET_APPOINTMENTS_REQUEST: "GET_APPOINTMENTS_REQUEST",
  GET_APPOINTMENTS_SUCCESS: "GET_APPOINTMENTS_SUCCESS",
  GET_APPOINTMENTS_FAILURE: "GET_APPOINTMENTS_FAILURE",
  GET_APPOINTMENT_REQUEST: "GET_APPOINTMENT_REQUEST",
  GET_APPOINTMENT_SUCCESS: "GET_APPOINTMENT_SUCCESS",
  GET_APPOINTMENT_FAILURE: "GET_APPOINTMENT_FAILURE",
  RESCHEDULE_APPOINTMENT_REQUEST: "RESCHEDULE_APPOINTMENT_REQUEST",
  RESCHEDULE_APPOINTMENT_SUCCESS: "RESCHEDULE_APPOINTMENT_SUCCESS",
  RESCHEDULE_APPOINTMENT_FAILURE: "RESCHEDULE_APPOINTMENT_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const patientConstants = {
  GET_PATIENTS_REQUEST: "GET_PATIENTS_REQUEST",
  GET_PATIENTS_SUCCESS: "GET_PATIENTS_SUCCESS",
  GET_PATIENTS_FAILURE: "GET_PATIENTS_FAILURE",
  MANAGE_PATIENT_REQUEST: "MANAGE_PATIENT_REQUEST",
  MANAGE_PATIENT_SUCCESS: "MANAGE_PAIENT_SUCCESS",
  MANAGE_PATIENT_FAILURE: "MANAGE_PATIENT_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const reportConstants = {
  GET_APPOINTMENT_REPORTS_REQUEST: "GET_APPOINTMENT_REPORTS_REQUEST",
  GET_APPOINTMENT_REPORTS_SUCCESS: "GET_APPOINTMENT_REPORTS_SUCCESS",
  GET_APPOINTMENT_REPORTS_FAILURE: "GET_APPOINTMENT_REPORTS_FAILURE",
  GET_PATIENT_REPORTS_REQUEST: "GET_PATIENT_REPORTS_REQUEST",
  GET_PATIENT_REPORTS_SUCCESS: "GET_PATIENT_REPORTS_SUCCESS",
  GET_PATIENT_REPORTS_FAILURE: "GET_PATIENT_REPORTS_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}