import { useState } from "react";
import { useKeyBind } from "react-keybinds";
import KeyboardAltOutlinedIcon from "@mui/icons-material/KeyboardAltOutlined";
import { Box, Modal, Stack, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", md: "50%" },
  bgcolor: "background.paper",
  borderRadius: 4,  
  boxShadow: 24,
  px: 4,
  py: 2
};

const Search = () => {  

  const {shortcuts} = useKeyBind();    
  const [ showModal, setShowModal ] = useState(false);

  return (
    <Box sx={{ width: "100%", ml: { xs: 0, md: 1 } }}>
      {shortcuts?.length > 0 && (
        <KeyboardAltOutlinedIcon
          sx={{
            color: "text.secondary",
            fontSize: { xs: 20, md: 24 },
            ml: 0.5,
            mr: 0.5,          
            "&:hover": {
              color: "primary.main",
              cursor: "pointer",
            },
          }}
          onClick={() => setShowModal(!showModal)}
        />
      )}
      <Modal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        aria-labelledby="keyboard-shortcut-modal"
        aria-describedby="keyboard-shortcut-modal"
      >
        <Box sx={style}>
          <Typography             
            variant="h6" 
            fontSize={16}
            sx={{ fontWeight: "bold", mb: 2 }}
          >
            Keyboard Shortcuts
          </Typography>   
          <Stack spacing={1}>
            {shortcuts?.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i).map((shortcut, index) => (
              <Box
                key={index}
                p={1.5}
                border={1}
                borderRadius={1}
                borderColor="divider"
              >
                <Typography
                  variant="h6"
                  fontSize={14}
                  sx={{ fontWeight: "bold" }}
                >
                  {shortcut?.label}
                </Typography>
                <Typography 
                  variant="body1"
                  fontSize={12}
                  color="text.secondary"
                >
                  {shortcut.description}
                </Typography>
                <Stack spacing={1} direction="row" mt={1}>
                  {shortcut?.display?.split(" ")?.map((item, index) => (
                    <Typography 
                      key={index} 
                      variant="body1" 
                      fontSize={12}
                      p={0.5}
                      border={1}
                      borderColor={"primary.main"}
                      minWidth={25}
                      textAlign={"center"}
                      borderRadius={1}
                      backgroundColor={"primary.lighter"}
                      color={"primary.main"}
                      fontWeight={"bold"}
                    >
                      {item}
                    </Typography>
                  ))}
                </Stack>
              </Box>
            ))}
          </Stack>       
        </Box>
      </Modal>
    </Box>
  );
}

export default Search;