import { reportConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  reportData: null,
  appointmentData: null,
  startDate: null,
  endDate: null,
  appointmentReportData: null,
  patientReportData: null,
};

const reportReducer = (state = initState, action) => {    
  switch (action.type) {    
    case reportConstants.GET_APPOINTMENT_REPORTS_REQUEST:
    case reportConstants.GET_PATIENT_REPORTS_REQUEST:        
      return {
        ...state,
        loading: true,
      };

    case reportConstants.GET_APPOINTMENT_REPORTS_SUCCESS:      
      return {
        ...state,  
        appointmentReportData: action.payload,
        loading: false,            
      };

    case reportConstants.GET_PATIENT_REPORTS_SUCCESS:
      return {
        ...state,  
        patientReportData: action.payload,
        loading: false,            
      };
    
    case reportConstants.GET_APPOINTMENT_REPORTS_FAILURE:
    case reportConstants.GET_PATIENT_REPORTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      
    case reportConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case reportConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default reportReducer;