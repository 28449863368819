import { patientConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  patients: null,   
  total: 0,
  page: 1,
  limit: 25,
};

const patientReducer = (state = initState, action) => {  
  let temp, index;
  switch (action.type) {    
    case patientConstants.GET_PATIENTS_REQUEST:
    case patientConstants.MANAGE_PATIENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case patientConstants.GET_PATIENTS_SUCCESS:
      return {
        ...state,      
        patients: action.payload?.patients,
        total: action.payload?.total,
        page: action.payload?.currentPage,
        limit: action.payload?.limit,
        loading: false,
      };

    case patientConstants.MANAGE_PATIENT_SUCCESS:
      index = state.patients.findIndex(patient => patient.id === action.payload.data.id);
      if(index > -1){
        temp = [...state.patients];
        temp[index] = action.payload.data;
      }else{
        temp = [action.payload.data, ...state.patients];
      }
      return {
        ...state,
        patients: temp,
        message: action.payload.message,
        loading: false,
      };     

    case patientConstants.GET_PATIENTS_FAILURE:
    case patientConstants.MANAGE_PATIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      
    case patientConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case patientConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default patientReducer;