import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { Box, Fab, Toolbar, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddIcon from '@mui/icons-material/Add';

import Breadcrumbs from "components/@extended/Breadcrumbs";
import navigation from "menu-items";
import { openDrawer } from "redux/actions";
import Drawer from "./Drawer";
import Header from "./Header";

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const { user } = useSelector((state) => state.user);
  
  const [open, setOpen] = useState(drawerOpen);

  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };
  
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: [5]?.includes(user?.role) ? false : !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <>      
      <Box sx={{ display: "flex", width: "100%" }}>
        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
        <Box component="main" sx={{ width: "100%", flexGrow: 1, p: { xs: 2, sm: 3 } }}>
          <Toolbar />
          <Breadcrumbs navigation={navigation} title />
          <Outlet />
        </Box>
      </Box>
      {[4]?.includes(user?.role) && <Fab 
        component={Link}
        to="/patient"
        color="success" 
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: 24,
          right: 16        
        }}
      >
        <AddIcon />
      </Fab>}    
    </>
  );
};

export default MainLayout;